import { createRouter, createWebHistory } from 'vue-router'
// import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import store from '@/store'
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
   
  },
  /**
   * Paginas de Login - recovery - register (SYSTEM)
   */
  {
    path:'/login/:error?',
    name: 'login',
    component: () => import('../views/system/authSystem/LoginView.vue'),
  },
  {
    path:'/recovery/:token?',
    name: 'recovery',
    component: () => import('../views/system/authSystem/RecoveryPassView.vue'),
  },
  {
    path:'/registre',
    name: 'registre',
    component: () => import('../views/system/authSystem/RegisterView.vue'),
  },
  {
    path:'/guest/:token',
    name: 'guest',
    component: () => import('../views/system/authSystem/GuestView.vue'),
  },
    /** Paginas de la app */
    {
    path: '/inici',
    name: 'inici',
    component: () => import('../views/HomeLoggedView.vue'),
    meta: {requireLogged:true}
    },
    /**Seccion materiales */
    {
      path: '/materials',
      name: 'Materials',
      component: () => import('../views/materiales/MaterialsView.vue'),
      meta: {requireLogged:true}
      },
      {
        path: '/materials/:slug',
        name: 'MaterialArticle',
        component: () => import('../views/materiales/MaterialsByTopicView.vue'),
        meta: {requireLogged:true}
      },
      /** Seccion Formularios */
      {
        path: '/formularis-i-enquestes',
        name: 'Formularis',
        component: ()=> import('../views/formularios/formListView.vue'),
        meta: {requireLogged:true}
      },
      {
        path: '/formularis-i-enquestes/edicio/:id?',
        name: 'FormulariEdit',
        component: ()=> import('../views/formularios/formEditView.vue'),
        meta: {requireLogged:true}
      },
      {
        path: '/formularis-i-enquestes/resultats/:id',
        name: 'FormulariResultats',
        component: ()=> import('../views/formularios/formResultatsView.vue'),
        meta: {requireLogged:true}
      },
      {
        path: '/formulari/:slug',
        name: 'FormulariPublic',
        component: ()=> import('../views/formularios/publicFormView.vue')
      },
      /** Seccion Biblioteca */
      {
        path: '/biblioteca',
        name: 'Biblioteca',
        component: ()=> import('../views/biblioteca/bibliotecaView.vue'),
        meta: {requireLogged:true}
      },
      /** Seccion webinars y tutoriales */
      {
        path: '/webinars-i-tutorials',
        name: 'Webinars',
        component: () => import('../views/webinars/webinarsView.vue'),
        meta: {requireLogged: true}
      },
      /** Seccion Usuarios */
      {
        path: '/perfil-usuari',
        name: 'Perfil',
        component: () => import('../views/user/profileView.vue'),
        meta: {requireLogged: true}
      },
      {
        path: '/admin/settings',
        name: 'AdminSettings',
        component: () => import('../views/user/adminSettingsView.vue'),
        meta: {requireLogged: true}
      },
      {
        path: '/admin',
        name: 'AdminDashboard',
        component: () => import('../views/user/adminAnalyticsView.vue'),
        meta: {requireLogged: true}
      },
  /** Páginas de SYSTEM */
  {
    path:'/about',
    name: 'about',
    component: () => import('../views/system/AboutView.vue'),
     meta:{requireLogged:true}
  },
/**admins */
{
  path:'/admin/crm',
  name: 'crm',
  component: () => import('../views/administrator/CrmView.vue'),
   meta:{requireLogged:true}
},
/**Paginas WHAT UP PROJECT */
{
path: "/inscripcio-whats-up",
name: "inscripcioWhatsup",
component: () => import("../views/inscripcioWhatsUpView.vue"),
meta: { requireLogged: true },
},
  // {
  //   path:'/notificacions',
  //   name: 'notificacions',
  //   component: () => import('../views/user/NotificationsListView.vue'),
  //    meta:{requireLogged:true}
  // },
  {
    path:'/notificacions/:id?',
    name: 'notificacions',
    // name: 'notificacionsDetail',
    component: () => import('../views/user/NotificationsView.vue'),
     meta:{requireLogged:true}
  },
  {
  path: "/admin/settings/faqs",
  name: "faqs",
  component: () => import("../views/administrator/faqListView.vue"),
  meta: { requireLogged: true },
  },
  {
  path: "/admin/settings/edition/:id?",
  name: "faqEdition",
  component: () => import("../views/administrator/faqEditorView.vue"),
  meta: { requireLogged: true },
  },
  {
  path: "/admin/settings/usuaris/:id?",
  name: "usuaris",
  component: () => import("../views/administrator/usersPanel.vue"),
  meta: { requireLogged: true },
  },
  {
    path: "/admin/settings/inscripcions/:id?",
    name: "inscripcions",
    component: () => import("../views/administrator/inscripcioPanel.vue"),
    meta: { requireLogged: true },
    },
    {
      path: "/admin/settings/manual-usuari",
      name: "manualUsuari",
      component: () => import("../views/administrator/manualUsuariView.vue"),
      meta: { requireLogged: true },
      },

  {
  path: "/:catchAll(.*)",
  name: "404",
  component: () => import("../views/404View.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(),
  // history: createWebHashHistory(),
  routes,
  linkExactActiveClass: 'active'
})
router.beforeEach((to, from, next)=> {
  const requireIsLogged = to.matched.some(item => item.meta.requireLogged);
  if(requireIsLogged) {


    if(store.state.user.token == null) 
    { 

      next({name: "login", params: {error: ''}})
    
    } else {
   //Cada vez que entramos en la página verificamos que el token es correcto y traemos la info basica del usuario.
   fetch(store.state.apiUrl+'service/userinfo?token='+store.state.user.token)
   .then(res=>res.json())
   .then(data=> {

     if(data.status == 'ERROR') {
      let error = data.message.replaceAll(" ", "-");
      error = error.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        next({name: 'login', params: {error: error}});

     } else {
       //Rellenar los datos:
             store.state.user.id = data.result.id;
             store.state.user.basicInfo = data.result.basicInfo;
       
           /** NOTE: Habría que evaluar si el usuario está activo o no */
           if(data.result.status == 0) {
             next({name: 'login', params: {error:'inactiu'}});
           }

           /** Traduccion para el front actual, que evalua este rol como un booleano en funcion del rol */
           if(store.state.user.basicInfo.role == 'administrador') {
             store.state.user.basicInfo.admin = true;
           }
         
       //---------
       store.state.user.status = data.result.status;
       next();
     }


   })
    }

 
  } else {
    next();
  }

})
export default router
