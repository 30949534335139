<template>
    <div class="faqPublic accordion">
            <div class="accordion-item " v-for="(item, index) in faqs" :key="index">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        :data-bs-target="'#'+item.id" aria-expanded="false" aria-controls="personalData">
                        {{item.question}}
                    </button>
                </h2>
                <div :id="item.id" class="accordion-collapse collapse ">
                    <div class="accordion-body p-md-5 p-3" v-html="item.response"> </div>
                </div>
            </div>    
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'faqPublic',
    props: {},
    components: {},
    data() {
        return {
            faqs: []
        }
    },
    computed: {
        ...mapState(['apiUrl'])
    },
    methods: {},
    created() {
        const headers = {
            "Content-Type": "application/json",
        };
        let requestOptions = {
            method: 'GET',
            headers: headers,
        };
        fetch(this.apiUrl + 'service/faqs', requestOptions)
            .then(response => (response.ok) ? response.json() : console.log(response.status))
            .then(result => {
                if (result.status == 'OK') {
                    // Tu código aquí si la respuesta es exitosa
                     this.faqs = result.results;
                     this.faqs = this.faqs.sort((a,b)=>{
                        if(a.question < b.question) {return -1}
                        if(a.question > b.question) {return 1}
                     return 0;

                     });
                } else {
                    // Tu código aquí si la respuesta no es exitosa
                     
                }
            })
            .finally(() => {
                // Tu código aquí que se ejecutará después de la promesa, sin importar si fue exitosa o no
               
            });
    },
    mounted() {},
}
</script>

<style scoped></style>