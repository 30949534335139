<!--
  Página de inicio pública de la APP
-->
<template>
  <div class="home">
    <!--Hero Banner -->
    <div class="container-fluid">
      <div class="row bg-cover py-5 px-3 hero-banner">
        <div class="col-12">
          <span class="hero-text f-medium">Com vas de salut mental?</span>
        </div>
      </div>
    </div>
    <!-- // -->

    <!-- Primera descripción -->
    <section class="py-5">
      <div class="container py-5">
        <div class="row">
          <div class="col-12">
            <p> El projecte “What’s up: com vas de salut mental?” (WUP) és un programa innovador que aposta per la
              millora
              de la cultura educativa en relació a la salut mental I que obre un procés de normalització i apoderament
              del
              jove, clau en la lluita contra l’estigma.</p>
            <p>El desconeixement o la por sobre què és una problemàtica de salut mental són les barreres que ens trobem
              tant als centres educatius per part de direcció I professorat, com a les famílies i entre els
              professionals
              dels espais educatius no formals.</p>
            <p>El What’s UP vol transformar la manera com es viu en relació a la salut mental. Un valor afegit que està
              relacionat amb el caràcter i sistema de valors de tota la comunitat educativa, tant a dins com a fora de
              l’institut.</p>

          </div>
        </div>
      </div>
    </section>
    <!-- // -->
    <!-- Vols implementar -->
    <section class="py-5 bg-primary text-white">
      <div class="spacer-1"></div>
      <div class="container py-5">

        <div class="row mb-5 text-center">
          <div class="col-12">
            <h2 class="h2 f-medium">Vols implementar el projecte?</h2>
            <span class="h4 f-medium">Aquí te’n donem les claus:</span>
          </div>
        </div>
        <div class="row mt-3 text-primary">
          <div class="col-lg-4 my-3">
            <SimpleCard title="Si ets centre de secundària i/o personal docent" img="images/1.png"
              @response="infoSecundaria = true; infoFamilia = false; infoProfesional = false" link="Más Info"></SimpleCard>

          </div>
          <div class="col-lg-4 my-3">
            <SimpleCard title="Si ets AFA i/o familia" img="images/2.png"
              @response="infoSecundaria = false; infoFamilia = true; infoProfesional = false" link="Más Info"></SimpleCard>

          </div>
          <div class="col-lg-4 my-3">
            <SimpleCard title="Si ets entitat I/o professional del lleure" img="images/3.png"
              @response="infoSecundaria = false; infoFamilia = false; infoProfesional = true" link="Más Info"></SimpleCard>

          </div>
        </div>

        <div class="row py-3">
          <div class="col-12">
            <div v-show="infoSecundaria">
              <h3 ><span >Si ets centre de secundària i/o
                  personal docent</span></h3>
              <p><span style="background-color: transparent;">Disposeu de </span><strong
                  style="background-color: transparent;">16 situacions d’aprenentatge</strong><span
                  style="background-color: transparent;"> dins del currículum de les matèries, adaptades a les hores
                  lectives, i avaluables, que inclouen continguts de salut mental de forma transversal per a 1r i 3r
                  d’ESO. A més a més, compteu amb </span><strong style="background-color: transparent;">6 càpsules
                  d’educació emocional </strong><span style="background-color: transparent;">competencials i
                  complementàries per l’hora de tutoria per 1r, 2n i 4t de l’ESO. Finalment, en els materials compteu
                  amb 7 vídeos de testimonis joves reals amb una problemàtica de salut mental.</span></p>
              <ul>
                <li><span style="background-color: transparent;">Les propostes tenen lloc dins del marc curricular
                    obligatori de l’alumne i </span><strong style="background-color: transparent;">no suposen una
                    preparació de material extra </strong><span style="background-color: transparent;">per part del
                    professorat, sinó una implementació de dinàmiques i activitats dins la seva activitat
                    regular.</span></li>
                <li><span style="background-color: transparent;">La </span><strong
                    style="background-color: transparent;">durada </strong><span
                    style="background-color: transparent;">del projecte depèn de la disponibilitat de cada centre. Es
                    pot realitzar una tria del nombre d’unitats didàctiques a treballar i una adaptació curricular a
                    mida.</span></li>
                <li><strong style="background-color: transparent;">No s’espera que el professorat tingui formació
                    específica en salut mental</strong><span style="background-color: transparent;">, ja que el
                    contingut a treballar és totalment curricular. Es parteix dels coneixements propis de la matèria i
                    les ganes de vincular-los amb la temàtica de salut mental.</span></li>
                <li><strong style="background-color: transparent;">El primer pas,</strong><span
                    style="background-color: transparent;"> i un dels més importants, </span><strong
                    style="background-color: transparent;">és que el propi professorat comenci a parlar-ne
                    obertament</strong><span style="background-color: transparent;"> per tal de poder entendre millor la
                    nostra salut emocional i la dels altres.</span></li>
                <li><span style="background-color: transparent;">Per tal de poder-ne fer tant una introducció com el
                    seguiment, el projecte compta amb</span><strong style="background-color: transparent;"> 6 càpsules
                    més de treball emocional pertutoria</strong><span style="background-color: transparent;"> on es
                    reforça la importància de les emocions per a la gestió de la nostra salut mental. Propostes
                    competencials complementàries per treballar a 1r, 2n i 4t d’ESO i abastar així tot el cicle complet,
                    potenciar-ne els seus efectes.</span></li>
              </ul>
              <p><br></p>
              <p><a href="https://www.youtube.com/watch?v=ogk39mLAvfM" target="_blank" style="background-color: transparent;">https://www.youtube.com/watch?v=ogk39mLAvfM</a></p>
              <p><br></p>
            
            
            </div>
            <div v-show="infoFamilia">
              <h3 ><span >Si ets AFA o família</span></h3>
              <p><span style="background-color: transparent;">La família és el principal referent i entorn de protecció
                  per als nostres fills i filles. És el model i mirall del qual reproduïm valors, actituds i
                  posicionaments davant de situacions vitals: allò que se’ns transmet i com se’ns transmet influeix en
                  les nostres reaccions davant diferents situacions, en la presa de decisions futures i en la nostra
                  actitud vital.</span></p>
              <p><span style="background-color: transparent;">Per aquest motiu, és important ser conscients de com ens
                  relacionem amb els nostres fills i filles i quins són els valors que estem transmetent. A continuació,
                  podeu trobar pautes i informació per ajudar-vos a promoure una bona salut mental a casa a través d’una
                  materials descarregables amb previ registre.</span></p>
              <p><br></p>
              <p><a href="https://www.youtube.com/watch?v=xLCRxD0zMV4" target="_blank" style="background-color: transparent;">https://www.youtube.com/watch?v=xLCRxD0zMV4&nbsp;</a>
              </p>
              <p><br></p>
            </div>
            <div v-show="infoProfesional">
              <h3 ><span >Si ets entitat i/o professional
                  de lleure&nbsp;</span></h3>
              <p><span style="background-color: transparent;">L’associacionisme i el lleure juvenil, ha esdevingut un
                  punt de trobada igualitari entre joves, on de forma autogestionada, es genera un espai educatiu de
                  gran valor. Els voluntaris i professionals d’aquestes entitats treballen constantment en valors i des
                  d’una perspectiva no discriminatòria, que no pot obviar l’estigma en salut mental, per la qual cosa
                  podeu trobar una sèrie de materials descarregables previ registre en el web</span></p>
              <p><br></p>
              <p><a href="https://www.youtube.com/watch?v=_I3fJWrEO3Q" target="_blank" style="background-color: transparent;">https://www.youtube.com/watch?v=_I3fJWrEO3Q&nbsp;</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="spacer-1"></div>
    </section>
    <!-- // -->

    <!-- El projecto Whast up -->
    <section class="py-5">
      <div class="container py-5 ">
        <div class="row mb-4">
          <div class="col-12 d-flex align-items-start justify-content-center" style="gap:15px">
            <h2 class="h2 f-medium text-primary">El Projecte </h2><img
              src="../assets/images/gr_logo_app_blue_horizontal.svg" height="50" alt="" style="margin-top:5px">
          </div>
        </div>
      </div>
      <div class="container py-5 ">
        <div class="row">
          <div class="col-12">
            <h3 class="h3 text-secondary f-demibold"> 1. Per què salut mental?</h3>
          </div>
        </div>
        <div class="row text-primary">
          <div class="col-md-6">
            <p>En cas que aparegui algun problema emocional, parlar-ne és el primer pas per demanar ajuda i evitar
              quedar-nos aïllats. Al contrari del que molts cops pensem, el silenci fa més gros el malestar. Com abans
              en
              parlem més fàcil serà de gestionar.</p>
          </div>
          <div class="col-md-6">
            <span class="quote" style="margin-left:20px">
              El 50% de trastorns de salut <br> mental apareixen en <br> l’adolescència, abans dels 14 anys.
            </span>
          </div>
        </div>
      </div>
      <hr class="hr">
      <div class="container py-5 ">
        <div class="row">
          <div class="col-12">
            <h3 class="h3 text-secondary f-demibold"> 2. Què és el WUP?</h3>
          </div>
        </div>
        <div class="row text-primary">
          <div class="col-md-6">
            <p> <em>What’s up: Com vas de salut mental? </em> (WUP) és un projecte creat per Obertament que vol arribar
              a
              tot l’entorn del jove transformant la cultura sobre la salut mental per a què es trenqui el tabú i es
              desmenteixin tots els prejudicis que hi ha</p>
          </div>
          <div class="col-md-6">
            <span class="quote" style="margin-left:20px">
              El 30% de persones amb un trastorn de salut mental s’han sentit discriminades pels companys i companyes de
              classe quan estaven en edat escolar.
            </span>
          </div>
        </div>
      </div>
      <hr class="hr">
      <div class="container py-5 ">
        <div class="row">
          <div class="col-12">
            <h3 class="h3 text-secondary f-demibold"> 3. Per què 360?</h3>
          </div>
        </div>
        <div class="row text-primary">
          <div class="col-md-6">
            <p> L’objectiu de l’enfocament 360 és que tothom implicat en l’educació dels joves remi a favor per a què
              aquests sentin que si tenen malestar emocional no se n’han d’avergonyir i en poden parlar al seu voltant.
              Per això cal que l’entorn del jove estigui ben preparat per oferir un espai segur on poder parlar
              obertament
              de salut mental.</p>
          </div>
          <div class="col-md-6">
            <span class="quote" style="margin-left:20px">
              1 de cada 5 persones amb trastorn mental han estat discriminades
              per part d’algun membre del professorat quan estaven en edat escolar.
            </span>
          </div>
        </div>
      </div>
      <hr class="hr">
      <div class="container py-5 ">
        <div class="row">
          <div class="col-12">
            <h3 class="h3 text-secondary f-demibold"> 4. Què aconseguirem?</h3>
          </div>
        </div>
        <div class="row text-primary">
          <div class="col-12">
            <p>El projecte fomenta des de tots els seus angles que els joves puguin conèixer què és la salut mental, la
              relacionin amb el benestar i malestar emocionals i en puguin parlar de forma oberta per tal de demanar
              ajuda
              quan ho necessitin sense esperar a que el problema es faci més gran.</p>
          </div>
        </div>
      </div>
      <hr class="hr">
      <div class="container py-5 ">
        <div class="row">
          <div class="col-12">
            <h3 class="h3 text-secondary f-demibold">5. Com ho fem?</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12">
            <h4 class="h5 f-demibold text-secondary">NORMALITZACIÓ</h4>
            <p class="text-primary">Els materials parteixen del coneixement sobre salut mental en general i estan relacionats amb les diverses àrees d’aprenentatge a nivell curricular.            </p>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <h4 class="h5 f-demibold text-secondary">APROFUNDIMENT SOBRE ESTIGMA I DISCRIMINACIÓ</h4>
            <p class="text-primary">• Les dinàmiques a l’aula preveuen obrir espais de debat i reflexió entre els joves
            </p>
            <p class="text-primary">• A partir de visualització de testimonis d’activistes joves que compateixen la seva història de vida </p>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <h4 class="h5 f-demibold text-secondary">ACOMPANYAMENT</h4>
            <p class="text-primary">• Recursos formatius per professorat perquè tinguin eines i recursos per abordar la salut mental a les aules </p>
            <p class="text-primary">• Seguiment i suport dels centres per part d’Obertament 
            </p>
          </div>
          <!-- <div class="col-lg-3 col-md-6 col-12">
            <h4 class="h5 f-demibold text-secondary">SUPORT</h4>
            <p class="text-primary">• Identificació del propi estat emocional i detecció de possible malestar</p>
            <p class="text-primary">• Facilitació d’oferiment i/o recerca d’ajuda quan sigui necessari</p>
          </div> -->
        </div>
      </div>
      <hr class="hr">
      <div class="container py-5 ">
        <div class="row">
          <div class="col-12">
            <h3 class="h3 text-secondary f-demibold">6. Des d’on ho fem?</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p class="text-primary">El Projecte What’s Up és modular, pot implementar-se per peces o tot sencer.
El gruix del projecte es desenvolupa als centres educatius, però també hem desenvolupat recursos complementaris:</p>

<p class="text-primary">Proposta curricular i competencial per tot el cicle de secundària, i càpsules d’educació emocional per l’hora de tutoria, en col·laboració amb el Departament d’Ensenyament, a implantar pel professorat dins d’algunes matèries del curriculum, de forma efectiva i amb continuïtat de centre. <br><br>Resultats:
</p>
<ul class="text-primary">
  <li>Més de 90 centres s’han beneficiat del WUP a Catalunya des del curs 2015-16.</li>
  <li>Els resultats obtinguts en l’avaluació recomanen estendre’l a tots aquells centres educatius que així ho sol·licitin.  </li>
  <li>Es disposa d’evidència empírica de la seva efectivitat a curt I mitjà termini.</li>
  <li>El 97% dels instituts que l’han provat aconsellen la seva implementació a la resta de centres.</li>
  <li>Un 91% dels centres té previst repetir el projecte el curs vinent.</li>
</ul>
          </div>
        </div>
        <!-- <div class="row my-2 ">
          <div class="col-lg-6 my-3">
            <div class="specialCard">
              <div class="number f-demibold">1</div>
              <p class="text-primary"><span class="f-bold">Proposta curricular i competencial</span> per a 1r i 3r
                d’ESO, i <span class="f-bold">càpsules de
                  recordatori</span> per a 2n i 4t, en col·laboració amb el Departament d’Ensenyament, a implantar pel
                professorat dins d’algunes matèries del curriculum, de forma efectiva i amb continuïtat de centre.</p>
              <div>
                <a href="#" class="btn btn-sm btn-secondary">Más Info</a>
              </div>
            </div>
          </div>
          <div class="col-lg-6 my-3">
            <div class="specialCard">
              <div class="number f-demibold">2</div>
              <p class="text-primary">Complements Suport : <span class="f-bold">eines i recursos</span> senzills
                adreçats a famílies i
                professionals del lleure i entitats juvenils que recolzaran la intervenció més enllà del centre escolar.
              </p>
              <div>
                <a href="#" class="btn btn-sm btn-secondary ">Más info famílies</a> <a href="#"
                  class="btn btn-sm btn-secondary">Más info entitats i professionals</a>
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <hr class="hr">
      <div class="container py-5 ">

        <div class="row">
          <div class="col-12">
            <h3 class="h3 text-secondary f-demibold">7. Qui ho pot fer?</h3>
          </div>
        </div>
        <div class="row mt-3 text-primary">
          <div class="col-lg-4 my-3">
            <SimpleCard title="Si ets centre de secundària i/o personal docent" img="images/1.png" ></SimpleCard>

          </div>
          <div class="col-lg-4 my-3">
            <SimpleCard title="Si ets AFA i/o familia" img="images/2.png" ></SimpleCard>

          </div>
          <div class="col-lg-4 my-3">
            <SimpleCard title="Si ets entitat I/o professional del lleure" img="images/3.png" >
            </SimpleCard>

          </div>
        </div>
      </div>
      <hr class="hr">
      <div class="container py-5 ">

        <div class="row">
          <div class="col-12">
            <h3 class="h3 text-secondary f-demibold">Resultats</h3>
          </div>
        </div>
        <div class="row mt-3 text-primary">
          <div class="col-12">
            <ul class="tick">
              <li>Més de 90 centres s’han beneficiat del WUP a Catalunya des del curs 2015-16.</li>
              <li> Els resultats obtinguts en l’avaluació recomanen estendre’l a tots aquells centres educatius que així
                ho sol·licitin<sup> 1</sup>.</li>
              <li> Es disposa d’evidència empírica de la seva efectivitat a curt I mitjà termini. <sup>2</sup></li>
              <li> El 100% dels instituts que l’han provat aconsellen la seva implementació a la resta de centres.</li>
              <li> Un 86% dels centres repetiran el projecte el curs vinent.</li>


              <li> Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint quas fuga dignissimos vero voluptatibus
                quis temporibus maxime architecto voluptatem porro excepturi odio magni dolor laborum molestias,
                voluptate doloribus officiis praesentium. Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Veritatis, quae corporis commodi, quod assumenda asperiores vitae voluptatum eos, quisquam perferendis
                quos nihil esse debitis fugit aliquid suscipit maxime quam aliquam! Lorem ipsum dolor, sit amet
                consectetur adipisicing elit. Modi, ut error illo adipisci laborum atque deleniti natus, minus quidem
                cumque, inventore vitae! Vitae ex earum doloremque tempora facere ipsa odio.</li>


            </ul>
          </div>

          <div class="row my-4">
            <div class="col-12">
              <p>SEGELL DE QUALITAT: Segons l’estudi de la Fundació Sant Joan de Déu per a la Recerca i la Docència i
                supervisat per l’Institut Català d’Avaluació de Polítiques Públiques (IVÀLUA)</p>
              <p>PROJECTE AVALUAT: L’avaluació d’impacte s’ha dut a terme a partir d’una investigació controlada no
                aleatoritzada, utilitzant escales internacionals que mesuren, per una banda, el canvi en les opinions I
                creences en salut mental I, per l’altra, el canvi en actituds i la intenció de comportament dels joves
                que han passat pel programa. [<a href="" class="text-secondary">Descarrega’t l’informe complet fent clic
                  aquí.</a>]</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- // -->

    <!-- Materiales FREE -->
    <section class="py-5 bg-secondary">
      <div class="container py-5">
        <div class="row my-2">
          <div class="col-12  text-center mb-4">
            <h2 class="h2 f-medium">Materials</h2>
          </div>
        </div>
        <div class="row my-2">
          <div class="col-12 text-center">
            <PublicMaterials />
          </div>
        </div>
      </div>
    </section>
    <!-- // -->

    <!-- FAQS -->
    <section class="py-5">
      <div class="container py-5">
        <div class="row my-2">
          <div class="col-12 text-center mb-4">
            <h2 class="h2 f-medium">Preguntes freqüents</h2>
          </div>
        </div>
        <div class="row my-2">
          <div class="col-12 text-center">
            <faqPublic />
          </div>
        </div>
      </div>
    </section>
    <!-- // -->
    <!-- FAQS -->
    <section class="py-5 bg-primary">
      <div class="container py-5">
        <div class="row my-2">
          <div class="col-12  text-center text-white mb-4">
            <h2 class="h2 f-medium">Contacta amb nosaltres</h2>
          </div>
        </div>
        <div class="row my-2">
          <div class="col-12 text-center">
            <contactForm></contactForm>
          </div>
        </div>
      </div>
    </section>
    <!-- // -->

  </div>
</template>

<script>
// @ is an alias to /src
import faqPublic from '@/components/faqs/faqPublic.vue';
// import router from '@/router';
import { mapActions, mapState } from 'vuex';
import SimpleCard from '@/components/SimpleCard.vue';
import contactForm from '@/components/forms/contactForm.vue';
import PublicMaterials from '@/components/PublicMaterials.vue';
export default {
  name: 'HomeView',
  components: {
    SimpleCard,
    contactForm,
    faqPublic,
    PublicMaterials
  },
  data() {
    return {
      infoSecundaria: false,
      infoFamilia: false,
      infoProfesional: false,
    }
  },
  methods: {
    ...mapActions(['getToken']),

  },
  computed: {
    ...mapState(['user'])
  },
  mounted() {
    //Hay que obtener el token para poder verificar si se realizo login o no.
    // this.getToken();
    // if (this.user.token) {
    //   router.push('/inici');
    // }
  },
}
</script>


<style scoped>
.hero-banner {
  background: url('../assets/images/home_hero_banner.jpg');
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-text {
  font-size: 54px;
  color: white;
}

.quote {
  font-size: clamp(1rem, 2vw, 28px);
  padding: 25px;
  display: inline-block;
  position: relative;
}

.quote::before {
  content: " ";
  background-image: url('../assets/images/gr_corner.svg');
  position: absolute;
  background-repeat: no-repeat;
  width: 30px;
  aspect-ratio: 1;
  top: 0;
  left: 0;
}

.quote::after {
  content: " ";
  background-image: url('../assets/images/gr_corner.svg');
  position: absolute;
  background-repeat: no-repeat;
  width: 30px;
  aspect-ratio: 1;
  bottom: 0;
  right: 0;
  transform: rotate(180deg);

}

.specialCard {
  border: 1px solid var(--bs-primary);
  padding: 80px 30px 30px 30px;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

}

.specialCard .number {
  font-size: clamp(1.4rem, 4vw, 54px);
  background: var(--bs-primary);
  position: absolute;
  top: -20px;
  padding: 15px 20px;
  display: block;
  line-height: .8;
  color: #fff;
}
</style>