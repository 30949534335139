<template>
    <nav>
        <ol class="breadcrumb">
            <li v-for="(crumb, index) in crumbs" :key="index" class="breadcrumb-item"
                :class="{ active: index === crumbs.length - 1 }">
                <router-link :to="crumb.path">{{ crumb.label }}</router-link>
            </li>
        </ol>
    </nav>
</template>

<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

export default {
    name: 'BreadCrumb',
    setup() {
        const route = useRoute()
        const crumbs = computed(() => {
            if (!route.matched) return []

            const path = route.path;

            let elements = path.split('/');
            let first = elements.shift(); /** Quitamos el primer elemento porque el inicio, la home, no es un path limpio, sino que es inici */


            let crumbCompilation = [];
            let acumulationPath = first;

            if (elements[0] != 'inici') {
                crumbCompilation.push({
                    label: 'Inici',
                    path: '/inici'
                });
            }

            elements.forEach(element => {
                acumulationPath += '/' + element;
                let label =  element.charAt(0).toUpperCase() + element.slice(1);
                label = label.replace(/-/g, " ");
                crumbCompilation.push({
                    label: label,
                    path: acumulationPath
                });

            });

            // const matched = route.matched
            // const homeRoute = { name: 'home', label: 'Inicio' }
            // if (!matched.length) return [homeRoute]
            // const lastMatched = matched[matched.length - 1]
            // const paths = lastMatched.path.split('/').filter((path) => path)
            // return [
            //     homeRoute,
            //     ...paths.map((path, index) => ({
            //     name: `${lastMatched.name}-path-${index}`,
            //     label: path,
            //     to: {
            //         path: `/${paths.slice(0, index + 1).join('/')}`,
            //         params: route.params,
            //         query: route.query,
            //     },
            //     })),
            // ]
            return crumbCompilation;
        });


        return { crumbs }
    },
    methods: {

    },
    mounted() {
    }
}
</script>

<style scoped>
.breadcrumb {
    font-size: 16px !important;
}

.breadcrumb a {
    text-decoration: none;
}

.breadcrumb li:not(:first-child) a {
    color: var(--bs-info) !important;
}
</style>