import { createStore } from 'vuex';
import router from '@/router';
import Cookies from "js-cookie";


export default createStore({
  state: {
    login: {
      loading:false,
      errorMsg: '' //Revisar esto
    },
    // apiUrl: 'https://api-comvas.gattorosso.es/v1/',
    // apiFileUrl: 'https://api-comvas.gattorosso.es/files/',
    backUrl:'https://back.whatsupobertament.org',
    apiUrl: 'https://back.whatsupobertament.org/v1/',
    apiFileUrl: 'https://back.whatsupobertament.org/files/',
    user: {
      id: 0,
      basicInfo: {
        userName: '',
        firstName: '',
        lastName: '',
        email: '',
        pic: '',
        admin: false,
        userHost: false,
        whatsInscription: false 
      },
      token: null,
      status: 0
    },
    /** Información sobre la APP */
    app: {
      version: 'Beta',
      author: '',
    },
    toasts: []
  },
  getters: {
  },
  mutations: {
    setToken(state, payload) {
      state.user.token = payload;
    },
    setUserId(state, payload) {
      state.user.id = payload;
    },
    setError(state, payload) {
      state.login.loading = false;
      state.login.errorMsg = payload;
    },
    setBasicInfo(state, payload) {
      state.user.basicInfo = payload;
      state.user.role = payload;
    },
    updateToast(state, payload) {
      state.toasts.push(payload)
      setTimeout(() => {
          if(state.toasts.length>0) {state.toasts.shift()}
      }, 3000);
    }
  },
  actions: {
    async loginFunction({commit, state}, user) {
      state.login.loading = true;
      try {
        const res = await fetch(state.apiUrl+'service/auth', {
          method: "POST",
          body: JSON.stringify(user),
          headers: {
              "Content-Type": "application/json"
          }
        })
       const resDB = await res.json();


       //Verificación!!!
       if(resDB.status == 'OK') {
        commit('setToken', resDB.result.token);
        commit('setUserId', resDB.result.user_id);
        commit('setError',  '');
        Cookies.set("appComVas", resDB.result.token);
        Cookies.set("appComVasU", resDB.result.user_id);
        router.push("/inici");
       } else {
        commit('setToken',null);
        commit('setError',  resDB.message);
       }
      } catch (error){
        console.log(error);
      }
    },
    getToken({commit}) {
      if(Cookies.get("appComVas")) {
        commit('setToken',Cookies.get("appComVas"));
       // return Cookies.get("appComVas");
      } else {
        commit('setToken',null);
       // return null
      }
    },
    getUserId({commit}) {
      if(Cookies.get("appComVasU")) {
        commit('setUserId',Cookies.get("appComVasU"));
      } else {
        commit('setUserId',null);
      }
    },
   
    logout({commit}) {
      Cookies.remove("appComVasU");
      Cookies.remove("appComVas");
      commit('setToken', null);
      commit('setUserId', null);
      commit('setBasicInfo', null); //Vaciar los datos
      router.push("/login")
    },
    pushToast({commit}, toast) {
        commit('updateToast', toast);
     
    },
    errorLoadToast({commit}) {
      commit('updateToast', {
        alert: "S'ha produït un error a la càrrega de dades. Si us plau, prova en un altre moment. Si l'error persisteix, posa't en contacte amb els administradors de la pàgina perquè et puguin donar una solució.",
        type: 'danger'
      })
    },

  },
  modules: {
  }
})
