<template>
    <header>
        <!--<nav><router-link to="/">Home</router-link> - <router-link to="/inici">Home Logged</router-link> - <router-link to="/about">About</router-link> - <router-link to="/login">Login</router-link> - <a href="#"  @click="logout()">Logout</a></nav> -->

        <div class="container">
            <div class="row align-items-start">
                <div class="col-3">
                    <router-link to="/inici" class="navbar-brand">
                        <img src="../assets/images/gr_logo_app.svg" height="90" alt="Whats up" title="Whats up" />
                    </router-link>
                </div>
                <div class="col-9">

                    <!-- menu -->
                    <nav class="navbar">


                        <navMenu :logged="$route.meta?.requireLogged || false" :isAdmin="user.basicInfo?.admin"
                            :links="menuApp.items" v-if="$route.name !='recovery'">

                            <template #icon>
                                Menú
                            </template>
                        </navMenu>

                        <!-- <router-link to="/registre" v-if="$route.name == 'home' || $route.name == 'login'" class="">Registrar-se</router-link> -->
                        <router-link to="/login" v-if="$route.name == 'home'" class="btn btn-outline-primary">Iniciar
                            sessió</router-link>



                        <navMenu v-if="$route.meta?.requireLogged || false" logged :isAdmin="user.basicInfo?.admin"
                            :links="menuUser.items" logoutSession>
                            <template #icon>
                                <IconUser />
                            </template>
                        </navMenu>




                    </nav>
                </div>
            </div>
        </div>


    </header>
</template>

<script>
import { IconUser } from '@tabler/icons-vue';
import navMenu from './header/navMenu.vue';
import { mapState, mapActions } from "vuex";

export default {
    name: 'HeaderApp',
    data() {
        return {
            msg: 'Encabezado',
            menuUser: {
                id: 0,
                active: false,
                items: [{
                    text: 'Perfil',
                    external: false,
                    admin: false,
                    logged: true,
                    link: '/perfil-usuari',
                },
                {
                    text: 'Notificacions',
                    external: false,
                    admin: false,
                    logged: true,
                    link: '/notificacions',
                },
                {
                    text: 'Paràmetres d\'Administraciò',
                    external: false,
                    admin: true,
                    logged: true,
                    link: '/admin/settings',
                },
                {
                    text: 'Analítiques',
                    external: false,
                    admin: true,
                    logged: true,
                    link: '/admin',
                },
                {
                    text: 'Contactos',
                    external: false,
                    admin: true,
                    logged: true,
                    link: '/admin/crm',
                }]
            },
            menuApp: {
                id: 1,
                active: false,
                items: [{
                    text: 'Projecte What\'s Up',
                    external: false,
                    logged: true,
                    admin: false,
                    link: '/inici',

                },
                {
                    text: 'Materials',
                    external: false,
                    logged: true,
                    admin: false,
                    link: '/materials',
                },
                // {
                //     text: 'Inscripció en What’s Up!',
                //     external: false,
                //     logged: true,
                //     admin:false,
                //     link: '/inscripcio-whats-up',
                // },
                {
                    text: 'Enquestes i formularis',
                    external: false,
                    logged: true,
                    admin: false,
                    link: '/formularis-i-enquestes',
                },
                {
                    text: 'Biblioteca Recursos',
                    external: false,
                    logged: true,
                    admin: false,
                    link: '/biblioteca',
                },
                {
                    text: 'Webinars i tutorials',
                    external: false,
                    logged: true,
                    admin: false,
                    link: '/webinars-i-tutorials',
                },
                {
                    text: 'Distintiu Antiestigma',
                    external: true,
                    logged: false,
                    admin: false,
                    link: 'https://compromisantiestigma.org/inici/ambit-educatiu/',
                },
                {
                    text: 'Campus virtual',
                    external: true,
                    logged: false,
                    admin: false,
                    link: 'https://campusvirtual.obertament.org/',
                },
                ]
            },
        }
    },
    methods: {
        ...mapActions(["logout"]),
        // closeAllMenu() {
        //     this.menuApp.active = false;
        //     this.menuUser.active = false;
        // }
    },
    computed: {
        ...mapState(["user"]),

    },
    mounted() {

    },
    components: {
        navMenu,
        IconUser,

    },
    created() {



    }

}
</script>


<style scoped>
header {
    padding-top: 40px;
    padding-bottom: 20px;
}

.navbar {
    align-items: center;
    justify-content: flex-end;
    gap: 30px
}
</style>