<!-- Menu genérico-->
<template>
   
    <div class="menuApp" :class="{ 'active': active }">
        <div class="closeOutSide" @click="active = false" v-if="active"></div>
        <div class="btnMenuApp" @click="toggleMenu">
            <div v-if="!active">
                <slot name="icon"></slot>
                <svg v-if="links.length > 0" xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-caret-down" width="20" height="20" viewBox="0 0 24 24"
                    stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M6 10l6 6l6 -6h-12"></path>
                </svg>
            </div>
            <div v-else><IconX/></div>
        </div>

        <Transition name="slide">
            <ul class="dropdownMenu" v-show="active">
                <li v-for="(item, index) in linksMenu" :key="index" @click="toggleMenu">

                    <a :href="item.link" target="_blank" v-if="item.external">{{ item.text }} <small>
                            <IconExternalLink size="15" />
                        </small></a>
                    <RouterLink v-else :to="item.link">{{ item.text }}</RouterLink>


                </li>

                <li v-if="logoutSession" @click="toggleMenu">
                    <a href="#" @click="logout()">Tancar sessió</a>
                </li>
            </ul>
        </Transition>

    </div>
</template>
<script>
import { IconExternalLink } from "@tabler/icons-vue";
import { mapActions } from "vuex";
import { IconX } from "@tabler/icons-vue";
export default {
    name: 'navMenu',
    data() {
        return {
           active: false
        }
    },
    props: {
        logged: {
            type: Boolean,
            default: false
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
        links: {
            type: Array,
            required: true
        },
        logoutSession: {
            type: Boolean,
            default: false
        },
        // active: {
        //     type: Boolean,
        //     default: false
        // }
    },
    emits: ['update:active'],
    // emits: ['toggleMenu'],
    methods: {
        ...mapActions(["logout"]),
            closeMenu() {
            // this.$emit('toggleMenu', !this.active);
            this.$emit('update:active', false);
        },
        toggleMenu() {
            this.active = !this.active;
        }
    },
    computed: {
        linksMenu() {
            return this.links.filter((item) => {
                return (!item.logged) || (item.admin && this.isAdmin) || (item.logged && this.logged && !item.admin);
            })
        }
    },
    components: {
        IconExternalLink,
        IconX
    }
}
</script>
<style scoped>
.menuApp {
    /* border:1px solid; */
    position: relative;
    color: var(--bs-primary);

    cursor: pointer;
    border: 1px solid transparent;
}

.menuApp.active {
    background-color: var(--bs-primary);
    color: white
}


.btnMenuApp {
    padding: 10px 20px;
    position: relative;
    z-index: 0;
}
.menuApp.active .btnMenuApp {
    z-index: 1;
}
.menuApp:hover {
    border: 1px solid var(--bs-primary);
}

.dropdownMenu {
    border: 1px solid var(--color-text-2);
    position: absolute;
    /* margin-top: 10px; */
    right: -1px;
    width: 100%;
    max-width: 600px;
    min-width: 300px;
    list-style: none;
    padding: 0px;
    background: white;
    z-index:2;
}


/* .dropdownMenu  li{
background-color: var(--bs-primary);
} */
.dropdownMenu li:hover {
    background-color: var(--bs-secondary);
}

.dropdownMenu li a {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    border-bottom: 1px solid var(--color-text-2);
}

.dropdownMenu li:hover a {
    color: white;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-enter-active {
    transition: all 0.2s ease-out;
}

.slide-leave-active {
    transition: all 0.2s ease-in;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(-30px);
    opacity: 0;
}

.closeOutSide {
    position: fixed;
    background-color: transparent;
    width: 100%;
    left: 0px;
    top: 0px;
    height: 100vh;
    z-index: 1;
}

</style>