<template>
    <Transition>
    <div class="alertNotificationApp position-fixed start-50 top-50 translate-middle bg-white p-4 text-center w-75 h-50 mb-3 d-flex flex-column justify-content-center border shadow" v-if="status">
        <slot>Pendiente incluir mensaje</slot>
        <button type="button" class="btn btn-primary mt-4" @click="status = false" >Tancar</button>
    </div>
</Transition>
</template>

<script>

export default {
    name: 'alertNotificationApp',
    props: {},
    components: {},
    data() {
        return {
            status:true
        }
    },
    computed: {},
    methods: {
    },
    created() {},
    mounted() {},
}
</script>

<style scoped>

</style>