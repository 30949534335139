<template>
    <div id="wrapper">
      <!-- <GrCheck></GrCheck> -->
      <HeaderApp></HeaderApp>
      <div class="container">
            <div class="row" v-if="!exemptBread.includes($route.name)">
                <div class="col-12"> <BreadCrumb></BreadCrumb></div>
            </div>
        </div>
     
      <main> <router-view /></main>
      
      <ToastAlert v-model:alerts="toasts" position="bottom" align="right" />
      <FooterApp></FooterApp>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import HeaderApp from '@/components/HeaderApp.vue';
import FooterApp from '@/components/FooterApp.vue';
import BreadCrumb from "./components/BreadCrumb.vue";
// import NotificationPush from "./components/NotificationPush.vue";
/** BORRAR ESTE COMPONENTE PARA PROD */
// import GrCheck from "./components/GrCheck.vue";
export default {
  data() {
    return {
      exemptBread : ['home', 'login','recovery', 'componentBuilder', 'FormulariPublic'],
      //exemptNotificationPush: ['home', 'login','recovery', 'notificacions']
    }
  },
  methods: {
    ...mapActions(["getToken"]),
  },
  computed: {
    ...mapState(["user", "toasts"]),
  },
  created() {
 this.getToken();

  },
  components: {
    HeaderApp,
    FooterApp,
    BreadCrumb,
    // NotificationPush,
    // GrCheck
  }
}
</script>
<style lang="scss">
a.router-link-active {}
.grModal {
    z-index: 99;
}
</style>
