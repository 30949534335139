import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/** Bootstrap */
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../scss/custom.scss"
import "./assets/css/style.css"

/** GR basic components */
import '@gattorosso/basic-components-app/basic-components-app.css';
import * as BasicComponentsApp from '@gattorosso/basic-components-app'

import AlertNotificationApp from './components/AlertNotificationApp.vue';

createApp(App).use(store).use(router).use(BasicComponentsApp).component('AlertNotificationApp',AlertNotificationApp).mount('#app')
