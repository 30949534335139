<template>
    <div class="contactForm">
        <div v-if="!formEnviado">
            <div class="row mb-3">
                <div class="col">
                    <input type="text" class="form-control" placeholder="Nom" aria-label="Nom" v-model="contact.name"
                        :class="{ 'is-valid': valid.name == 1, 'is-invalid': valid.name == 2 }">
                </div>
                <div class="col">
                    <input type="text" class="form-control" placeholder="Cognom" aria-label="Cognom"
                        v-model="contact.surname" :class="{ 'is-valid': valid.surname == 1, 'is-invalid': valid.surname == 2 }">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <input type="email" class="form-control" placeholder="Correu electrònic" aria-label="Correu electrònic"
                        v-model="contact.email" :class="{ 'is-valid': valid.email == 1, 'is-invalid': valid.email == 2 }">
                </div>
                <div class="col">
                    <input type="date" class="form-control" v-model="contact.birthday">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <input type="text" class="form-control" placeholder="Centre educatiu" aria-label="Centre educatiu"
                        v-model="contact.institution"
                        :class="{ 'is-valid': valid.institution == 1, 'is-invalid': valid.institution == 2 }">
                </div>
                <div class="col">
                    <!-- TODO: ESTO TORNARLO A NUMERO! -->
                    <input type="text" class="form-control" placeholder="Codi Postal" aria-label="Codi Postal"
                        v-model="contact.postalCode">
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <select class="form-select" v-model="contact.role"
                        :class="{ 'is-valid': valid.role == 1, 'is-invalid': valid.role == 2 }">
                        <option selected disabled value="">Seleccioneu una opció</option>
                        <option>Docent</option>
                        <option value="Alumne">Alumne/a</option>
                        <option>Vull compartir la meva experiencia</option>
                    </select>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <textarea class="form-control" rows="3" placeholder="Deixa un missatge"
                        v-model="contact.message"></textarea>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col text-start">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="contact.privacy"
                            :class="{ 'is-valid': valid.privacy == 1, 'is-invalid': valid.privacy == 2 }">
                        <label class="form-check-label">
                            He llegit els termes i condicions del servei, i accepto l'ús de les meves dades perquè obertament es pugui posar en contacte amb mi per respondre als meus dubtes, a més d'utilitzar les meves dades amb finalitats estadístiques.
                        </label>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <button class="btn btn-secondary" @click="submitForm()">Enviar</button>
                </div>
            </div>
        </div>
        <div v-else class="response text-center p-4 bg-white">
            <LoaderBlock :active="loading"></LoaderBlock>
            <div v-show="!loading">
                <div class="alert alert-success" v-if="status == 200">
                    <p class="m-0">Gracias por compartir con nosotros tu mensaje</p>
                </div>
                <div class="alert alert-danger" v-else>
                    <p class="m-0">Se ha producido un error y no hemos podido recibir tu mensaje. Vamos a trabajar en ello. Por favor,
                        prueba más tarde.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'contactForm',
    props: {},
    components: {},
    data() {
        return {
            formEnviado: false,
            status: 0,
            loading: false,
            contact: {
                name: "",
                surname: "",
                email: "",
                phone: "borrar",
                message: "",
                institution: "",
                postalCode: "",
                role: "",
                birthday: "",
                privacy: false
            },
            valid: {
                name: null,
                surname: null,
                email: null,
                //phone: null,
                // message: null,
                institution: null,
                //  postalCode: null,
                role: null,
                //birthday: null,
                privacy: null
            }
        }
    },
    computed: {
        ...mapState(['apiUrl']),
        test() {
            return (item)=> {
                return item;
            }
        }
    },
    methods: {
        validation() {
            let required = ["name", "surname", "email", "institution", "role"];
            let error = 0;
            required.forEach(element => {
                if (this.contact[element] == '') {
                    error++;
                    this.valid[element] = 2;
                } else {
                    this.valid[element] = 1;
                }
            });


            if (!this.contact.privacy) {
                error++;
                this.valid.privacy = 2;
            } else {
                this.valid.privacy = 1;
            }


            if (error == 0) { return true; } else { return false }

        },
        submitForm() {
            let validation = this.validation();
            if (validation) {
                this.formEnviado = true;
                this.loading = true;
                const headers = {
                    "Content-Type": "application/json",
                    // "Token": this.user.token
                };
                const raw = JSON.stringify(this.contact);

                let requestOptions = {
                    method: 'POST',
                    headers: headers,
                    body: raw
                };
                fetch(this.apiUrl + 'service/new-contact', requestOptions)
                    .then(response => (response.ok) ? response.json() : console.log(response.status))
                    .then(result => {
                        if (result.status == 'OK') {
                            // Tu código aquí si la respuesta es exitosa
                            this.status = 200;

                        } else {
                            // Tu código aquí si la respuesta no es exitosa
                            this.status = 400;
                        }
                    })
                    .finally(() => {
                        // Tu código aquí que se ejecutará después de la promesa, sin importar si fue exitosa o no
                        this.loading = false;
                    });
            }
        }
    },
    created() { },
    mounted() { },
}
</script>

<style scoped></style>