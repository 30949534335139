<template>
    <div class="PublicMaterials">
        
        <div class="row my-3">
            <div class="col-12">
                <div v-for="(item, index) in materials" :key="index" class="bg-light px-4 py-2 border rounded text-start d-flex justify-content-between align-items-center">
                    <!-- <a href="" class="text-primary" style="text-decoration:none">Probando</a> -->
                     <span class="text-primary">{{item.name}}</span>
                     <a class="btn btn-primary" target="_blank"   :href="backUrl+item.link"
                     :download="item.name"><span>Descarregar</span></a>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

import { mapState } from 'vuex';
export default {
    name: 'PublicMaterials',
    props: {},
    components: {},
    data() {
        return {
            materials: []
        }
    },
    computed: {
        ...mapState(['backUrl','apiUrl'])
    },
    methods: {},
    created() {
        const headers = {
            "Content-Type": "application/json",
        };
        let requestOptions = {
            method: 'GET',
            headers: headers,
        };
        fetch(this.apiUrl + 'service/materials-public?' + new URLSearchParams({
        pagination: 3
        }), requestOptions)
            .then(response => (response.ok) ? response.json() : console.log(response.status))
            .then(result => {
                if (result.status == 'OK') {
                    // Tu código aquí si la respuesta es exitosa
                     this.materials = result.materials;
                } else {
                    // Tu código aquí si la respuesta no es exitosa
                     
                }
            })
            .finally(() => {
                // Tu código aquí que se ejecutará después de la promesa, sin importar si fue exitosa o no
               
            });
    },
    mounted() {},
}
</script>

<style scoped></style>import { mapState } from 'vuex';

