<template>
    <footer>
       <div class="container">

           <div class="row my-4 pb-2">
            <div class="col-12">
                <img src="../assets/images/gr_obertment_logo.svg" alt="Obertament" width="90%" height="auto" style="max-width: 220px;" title="Obertament">
            </div>
           </div>
           <div class="row my-4">
                <div class="col-12 d-flex justify-content-center flex-md-row " style="gap:50px">
                    <a href="https://www.facebook.com/Obertament" target="_blank" rel="noopener noreferrer">
                        <img src="../assets/images/gr_ico_face.svg" width="auto" height="35" alt="facebook">
                    </a>
                    <a href="https://twitter.com/obertament_sm" target="_blank" rel="noopener noreferrer">
                        <img src="../assets/images/gr_ico_twitter.svg" width="auto" height="35" alt="facebook">
                    </a>
                    <a href="https://www.instagram.com/obertament_sm/" target="_blank" rel="noopener noreferrer">
                        <img src="../assets/images/gr_ico_insta.svg" width="auto" height="35" alt="facebook">
                    </a>
                    <a href="https://www.youtube.com/user/obertament" target="_blank" rel="noopener noreferrer">
                        <img src="../assets/images/gr_ico_youtube.svg" width="auto" height="35" alt="facebook">
                    </a>
                    <a href="https://es.linkedin.com/company/obertament" target="_blank" rel="noopener noreferrer">
                        <img src="../assets/images/gr_ico_linkedin.svg" width="auto" height="35" alt="facebook">
                    </a>
                </div>
           </div>
     
           <div class="row my-5">
                <div class="col-12">
                    <router-link class="btn btn-outline-primary" to="/login">Iniciar Sessió</router-link>    
                </div>
           </div>
           <div class="row my-4">
            <div class="col-12 d-flex justify-content-center flex-md-row flex-column align-items-center" style="gap:50px">
                    <img src="../assets/images/gr_logo_generalitat.svg" alt="" width="auto" height="60" />
                    <img src="../assets/images/gr_logo_caixa.svg" alt="" width="auto" height="60" />
                    <img src="../assets/images/gr_logo_ajuntament.svg" alt="" width="auto" height="60" />
                    <img src="../assets/images/gr_logo_diputacio.svg" alt="" width="auto" height="60" />
            </div>
           </div>
           <div class="row my-4">
                <div class="col-12 d-flex justify-content-center flex-md-row flex-column align-items-center" style="gap:50px">
                    <a href="https://www.obertament.org/privacitat" target="_blank">Política de privacitat i protecció de dades personals</a>
                    <a href="https://www.obertament.org/cookies" target="_blank">Avis legal I Política de cookies</a>
                    <a href="https://www.obertament.org/campanyes" target="_blank">Aliats i col·laboradors</a>
                    <!-- <router-link to="">Política de privacitat i protecció de dades personals</router-link>
                    <router-link to="">A</router-link>
                    <router-link to=""></router-link> -->
                </div>
           </div>
      
       </div>

       <div class="copyright">Obertament &copy; {{ year }} - powered by Gatto Rosso &reg;</div>
    </footer>
</template>

<script>
export default {
    name: 'FooterApp',
    data () {
        return {
            msg: 'Footer  de la APP',
            year: ''
        }
    },
    created() {
        this.year = new Date().getFullYear();    
    },
}
</script>

<style scoped>
footer {
    text-align: center;
    border-top:1px solid #D8D5D5;
    padding-top: 40px;
}

.copyright {
    font-size: 0.8em;
    padding: 20px 0px;
}

</style>