<template>
      <div class="card h-100">
              <img :src="img" class="card-img-top" alt="..." v-if="img">
              <div class="card-body d-flex flex-column justify-content-between">
                <p class="h4 f-demibold lh-1" >{{title}}</p>

                <div v-if="url && link"> <a :href="url" class="btn btn-secondary btn-sm mt-4">{{link}}</a></div>
                <div v-else-if="link && !url"><button type="button" class="btn btn-secondary btn-sm mt-4" @click="$emit('response', true)">{{ link }}</button></div>
                
              </div>
        </div>
</template>

<script>
export default {
    name: 'SimpleCard',
    props: {
        title: String,
        img: String,
        url: String,
        link: String
    }
}
</script>

<style scoped></style>